import { regexEmail } from '../regex';
import { getEmptyValues } from './common';

const getLengthErrorMessage = (label, length) => `${label} must contain minimum ${length} characters`;

export function enterpriseClientValidator({ integrationService: _, ...values }) {
  const trimmedValues = Object.keys(values).reduce((acc, key) => {
    acc[key] = values[key].trim();

    return acc;
  }, {});

  const errors = { };

  if (trimmedValues.firstName.length < 2) {
    errors.firstName = getLengthErrorMessage('First name', 2);
  }

  if (trimmedValues.lastName.length < 2) {
    errors.lastName = getLengthErrorMessage('Last name', 2);
  }

  if (trimmedValues.companyName.length < 2) {
    errors.companyName = getLengthErrorMessage('Company name', 2);
  }

  if (!regexEmail.test(trimmedValues.email)) {
    errors.email = 'Invalid email address';
  }

  if (Number.isNaN(Number(trimmedValues.amount))) {
    errors.amount = 'Amount should be a number';
  }

  if (Number.isNaN(Number(trimmedValues.companySize))) {
    errors.companySize = 'Company size should be a number';
  }

  return { ...errors, ...getEmptyValues(trimmedValues) };
}