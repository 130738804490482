import { useFormik } from 'formik';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BsCheck, BsXCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Row,
  Card,
  Button,
  Spinner,
  Container,
  FormGroup,
  FormLabel,
  FormControl
} from 'react-bootstrap';

import { FormInput, RadioButton, PhoneInput, FormSelect } from 'components';
import { enterpriseDataSelector } from 'store/companies/selectors';
import { createEnterpriseCompany } from 'store/companies/actions';
import { enterpriseClientValidator } from 'utils/validators';
import { RESET_ENTERPRISE } from 'store/companies/types';
import { phoneAreaCodes } from 'utils/constants';
import integrationServices from 'utils/constants/integrationServices';

import { label, buttonCheckIcon, buttonCancelIcon, creationContainer } from './enterprise-client.module.scss';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  companyName: '',
  companySize: '',
  areaCode: '',
  amount: '',
  interval: '',
  integrationService: ''
};

const EnterpriseClient = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, isCreated } = useSelector(enterpriseDataSelector);

  const form = useFormik({
    initialValues,
    validate: enterpriseClientValidator,
    onSubmit() {
      dispatch(createEnterpriseCompany(form.values));
    }
  });

  const handleCancel = () => {
    dispatch({ type: RESET_ENTERPRISE });
    history.replace('/companies');
  };

  const handleAreaCodeChange = ({ value }) => {
    form.setFieldValue('areaCode', value);
  };

  const handleIntegrationServiceChange = ({ value }) => {
    form.setFieldValue('integrationService', value);
  };

  useEffect(() => {
    if (isCreated) {
      history.replace('/companies');
    }
  }, [isCreated]);

  useEffect(() => () => {
    dispatch({ type: RESET_ENTERPRISE });
  }, []);

  return (
    <div className="content">
      <Card className="p-3">
        <Container fluid>
          <Row>
            <Col xs="12" md="3">
              <FormInput
                name="firstName"
                placeholder="Type"
                label="First Name of the Owner:"
                value={form.values.firstName}
                onChange={form.handleChange}
                isInvalid={form.touched.firstName && !!form.errors.firstName}
                error={form.errors.firstName}
              />
            </Col>
            <Col xs="12" md="3">
              <FormInput
                name="lastName"
                placeholder="Type"
                value={form.values.lastName}
                onChange={form.handleChange}
                isInvalid={form.touched.lastName && !!form.errors.lastName}
                error={form.errors.lastName}
                label="Last Name of the Owner:"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="3">
              <FormInput
                name="companyName"
                placeholder="Type"
                value={form.values.companyName}
                onChange={form.handleChange}
                isInvalid={form.touched.companyName && !!form.errors.companyName}
                error={form.errors.companyName}
                label="Company Name:"
              />
            </Col>
            <Col xs="12" md="3">
              <FormInput
                name="companySize"
                placeholder="Type"
                value={form.values.companySize}
                onChange={form.handleChange}
                isInvalid={form.touched.companySize && !!form.errors.companySize}
                error={form.errors.companySize}
                label="Company Size:"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="3">
              <FormInput
                name="email"
                value={form.values.email}
                onChange={form.handleChange}
                isInvalid={form.touched.email && !!form.errors.email}
                error={form.errors.email}
                placeholder="Type"
                label="Email:"
              />
            </Col>
            <Col xs="12" md="3">
              <PhoneInput
                name="phone"
                value={form.values.phone}
                onChange={form.handleChange}
                isInvalid={form.touched.phone && !!form.errors.phone}
                error={form.errors.phone}
                placeholder="Type"
                label="Phone Number:"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="3">
              <FormSelect
                name="areaCode"
                options={phoneAreaCodes}
                value={form.values.areaCode}
                onChange={handleAreaCodeChange}
                isInvalid={form.touched.areaCode && !!form.errors.areaCode}
                error={form.errors.areaCode}
                placeholder="Select an area code"
                label="Area Code:"
              />
            </Col>
            <Col xs="12" md="3">
              <FormInput
                id="amount"
                name="amount"
                value={form.values.amount}
                onChange={form.handleChange}
                isInvalid={form.touched.amount && !!form.errors.amount}
                error={form.errors.amount}
                placeholder="Type"
                label="Amount to be Charged per Period:"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="3">
              <FormSelect
                name="integrationService"
                options={integrationServices}
                value={form.values.integrationService}
                onChange={handleIntegrationServiceChange}
                isInvalid={form.touched.integrationService && !!form.errors.integrationService}
                error={form.errors.integrationService}
                placeholder="Select integration service name"
                label="Integration service:"
              />
            </Col>
            <Col xs="12" md="3">
              <FormGroup>
                <FormLabel className={label}>Period:</FormLabel>
                <div className="d-flex">
                  <RadioButton className="mr-3" name="interval" labelText="Monthly" value="monthly" onChange={form.handleChange} />
                  <RadioButton name="interval" labelText="Annualy" value="annual" onChange={form.handleChange} />
                </div>
                {(form.touched.interval && !!form.errors.interval)
                  && <FormControl.Feedback type="invalid" className="d-block">{form.errors.interval}</FormControl.Feedback>}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6" className={creationContainer}>
              <Button type="button" variant="gray" className="mr-2" onClick={handleCancel}>
                <BsXCircle className={buttonCancelIcon} />
                Cancel
              </Button>
              <Button type="button" variant="orange" onClick={form.submitForm} disabled={loading}>
                {loading
                  ? <Spinner className="mr-2" size="sm" animation="border" variant="light" />
                  : <BsCheck className={buttonCheckIcon} />}
                Create
              </Button>
              <p className={classNames(label, 'mt-2')}>
                Once you create an enterprise client, a checkout form with<br /> password set up will be sent to the email address you<br /> entered in this form
              </p>
            </Col>
          </Row>
        </Container>
      </Card>
    </div>
  );
};

export default EnterpriseClient;