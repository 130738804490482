import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';

import { formLabel, formInput, formError } from './form-input.module.scss';

export const FormInput = ({ label, name, placeholder, value, onChange, isInvalid, error }) => (
    <FormGroup>
      <FormLabel className={formLabel} htmlFor={name}>{label}</FormLabel>
      <FormControl
        name={name}
        className={formInput}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        isInvalid={isInvalid}
        autoComplete="none"
      />
      <FormControl.Feedback type="invalid" className={formError}>{error}</FormControl.Feedback>
    </FormGroup>
  );

FormInput.defaultProps = {
  placeholder: '',
  isInvalid: false,
  error: ''
};

FormInput.propTypes = {
  isInvalid: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};