import React from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { updateNotification } from 'store/updates/selectors';
import { ReactComponent as DashboardSvg } from 'assets/svg/dashboard-icon.svg';
import { ReactComponent as UpdateSvg } from 'assets/svg/update-icon.svg';
import { ReactComponent as CompaniesSvg } from 'assets/svg/companies.svg';

import './sidebar.scss';


const Sidebar = () => {

    const isNewUpdates = useSelector(updateNotification, isEqual);

    return (
        <div className="sidebar">
            <div className="brand-area">
                <Link className="logo" to="/">
                    <img src="/logo.svg" alt="logo-desktop"/>
                    <img src="/logo-small.svg" alt="logo-desktop"/>
                </Link>

                <Nav
                    className="navbar"
                    activeKey="/home"
                    // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
                >
                    <div className="sidebar-sticky">&nbsp;</div>
                    <Nav.Item>
                        <NavLink className="nav-link" to="/home">
                            <DashboardSvg/>
                            <span className="nav-link-name">Dashboard</span>
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to="/updates">
                            <UpdateSvg/>
                            <span className={`nav-link-name ${isNewUpdates ? 'new-update' : ''}`}>Updates</span>
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to="/companies">
                            <CompaniesSvg/>
                            <span className="nav-link-name">Companies</span>
                        </NavLink>
                    </Nav.Item>
                </Nav>
            </div>
        </div>
    );
};

export default Sidebar;