import moment from 'moment';
import { isEqual } from 'lodash';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import React, { useState, useEffect, useCallback } from 'react';
import { BsCheckLg, BsTrash, BsExclamationCircle } from 'react-icons/bs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Loader from 'components/loader';
import DataTable from 'components/data-table';

import { WarningModal } from 'components';
import { callCompanies, callContacts, callUsers, setCompany, deleteCompany, acceptCompany } from '../../store/companies/actions';
import { addEnterpriseButton, iconAccept, iconDecline } from './companies.module.scss';
import { headers, tableWrapperStyle } from './constants';
import { useDidUpdate } from '../../hooks';
import {
  selectedCompanyIdSelector,
  companiesDataSelector,
  contactsDataSelector,
  usersDataSelector
} from '../../store/companies/selectors';

const companiesTableHeader = () => headers.map(({ value, className }) => <th key={value} className={className}>{value}</th>);


const Companies = () => {
  const dispatch = useDispatch();
  const selectedCompanyId = useSelector(selectedCompanyIdSelector, shallowEqual);

  const defaultQueries = {
    limit: 10,
    page: 1,
    s: ''
  };

  const {
    isCompaniesFetched,
    isCompaniesLoading,
    companiesData
  } = useSelector(companiesDataSelector, isEqual);

  const {
    isUsersFetched,
    isUsersLoading,
    usersData
  } = useSelector(usersDataSelector, isEqual);

  const {
    isContactsFetched,
    isContactsLoading,
    contactsData
  } = useSelector(contactsDataSelector, isEqual);

  const [companiesQueries, setCompaniesQueries] = useState(defaultQueries);
  // const [selectedCompany, setSelectedCompany] = useState();
  const [usersQueries, setUsersQueries] = useState(defaultQueries);
  const [contactsQueries, setContactsQueries] = useState(defaultQueries);
  const [tab, setTab] = useState('companies');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState({});

  // const isLoading = (isCompaniesLoading || isUsersLoading || isContactsLoading);

  const selectCompany = e => {
    const { id } = e.currentTarget.dataset;
    dispatch(setCompany(+id));
  };

  function loadCompanies() {
    !isCompaniesLoading && dispatch(callCompanies(companiesQueries));
  }

  function loadUsers() {
    !isUsersLoading && dispatch(callUsers(selectedCompanyId, usersQueries));
  }

  function loadContacts() {
    !isContactsLoading && dispatch(callContacts(selectedCompanyId, contactsQueries));
  }

  const paginationHandler = handler => page => {
    handler(prevState => ({
      ...prevState,
      page
    }));
  };

  const searchHandler = handler => searchText => {
    handler(prevState => ({
      ...prevState,
      page: 1,
      s: searchText
    }));
  };

  const changeActiveTab = key => {
    setTab(key);
    if (key === 'users') {
      loadUsers();
    }

    if (key === 'contacts') {
      loadContacts();
    }
  };

  useEffect(() => {
    if (!selectedCompanyId) {
      !isCompaniesFetched && loadCompanies();
    } else {
      !isUsersFetched && loadUsers();
      !isContactsFetched && loadContacts();
    }
  }, []);

  useDidUpdate(() => {
    setUsersQueries(defaultQueries);
    setContactsQueries(defaultQueries);
  }, [selectedCompanyId]);

  useDidUpdate(() => {
      loadCompanies();
  }, [companiesQueries]);

  useDidUpdate(() => {
    if (tab === 'users') {
      loadUsers();
    } else if (tab === 'contacts') {
      loadContacts();
    }

  }, [usersQueries, contactsQueries, tab]);

  const handleRemoveContact = e => {
    const { id } = e.currentTarget.dataset;
    setModalProps({
      warningText: 'Are you sure to delete the company?',
      acceptText: 'Delete',
      onAccept: async () => {
        await dispatch(deleteCompany(id, companiesQueries));
        setIsModalVisible(false);
      },
      onCancel: () => setIsModalVisible(false)
    });
    setIsModalVisible(true);
  };

  const handleAcceptCompany = e => {
    const { id } = e.currentTarget.dataset;
    setModalProps({
      warningText: 'Are you sure to accept the company?',
      acceptText: 'Accept',
      onAccept: async () => {
        await dispatch(acceptCompany(id, companiesQueries));
        setIsModalVisible(false);
      },
      onCancel: () => setIsModalVisible(false)
    });
    setIsModalVisible(true);
  };

  const companiesTableBody = useCallback(() => (!companiesData.companies.length
      ? (
        <tr>
          <td colSpan="7" className="table-no-information text-center p-4 text-muted">No results found</td>
        </tr>
      )
      : (companiesData.companies.map(company => (
          <tr
            className={selectedCompanyId === company.id ? 'selected' : 'selectable'}
            key={company.id}
            onClick={selectCompany}
            data-id={company.id}
          >
            <td>
              <span className="d-flex align-items-center">
                {(company.hasPendingCampaigns || company.hasPendingMessages)
                  && (
                    <BsExclamationCircle
                      size="14"
                      className="mr-2"
                      color="red"
                      title={`Fraudulent ${company.hasPendingCampaigns ? 'campaigns' : 'messages'} detected for review`}
                    />
                )}
                {company.name} {company.deletedAt && '(Deleted)'}
                <Link to={`/campaigns/${company.id}`} className="messages-link messages-link-gray ml-auto">
                  Campaigns
                </Link>
                <Link to={`/messages/${company.id}`} className="messages-link">
                  Messages
                </Link>
              </span>
            </td>
            <td>{moment(company.createdAt).format('MM/DD/YYYY')}</td>
            <td>{company.email}</td>
            <td>{company.owner}</td>
            <td>{company.userCount}</td>
            <td>{company.contactsCount}</td>
            <td>
              {!company.deletedAt && (
                <>
                {!company.accepted && (
                  <BsCheckLg size="14" className={iconAccept} data-id={company.id} onClick={handleAcceptCompany} />
                )}
                  <BsTrash size="14" data-id={company.id} onClick={handleRemoveContact} className={iconDecline} />
                </>
              )}
            </td>
          </tr>
        )))), [companiesData, selectedCompanyId]);

  return (
    <div className="content">
      <Tabs
        className="table-data-nav"
        activeKey={tab}
        onSelect={changeActiveTab}
      >
        <Tab eventKey="companies" title="Companies">
          {!isCompaniesFetched ? (
            <Loader/>
          ) : (
            <section className="position-relative">
              <Link to="/companies/add-enterprise-client">
                <button type="button" className={addEnterpriseButton}>
                  Add Enterprise Client
                </button>
              </Link>
              <DataTable
                tableHeader={companiesTableHeader}
                tableBodyContent={companiesTableBody}
                dataObj={companiesData}
                onRemoveHandler={handleRemoveContact}
                paginationHandler={paginationHandler(setCompaniesQueries)}
                isLoading={isCompaniesLoading}
                searchIt={searchHandler(setCompaniesQueries)}
                onCompanyClick={selectCompany}
                selectedCompanyId={selectedCompanyId}
                wrapperStyle={tableWrapperStyle}
              />
            </section>
          )}
        </Tab>
        <Tab eventKey="users" title="Users">
          {!isUsersFetched ? (
            <Loader/>
          ) : (
            <DataTable
              dataObj={usersData}
              paginationHandler={paginationHandler(setUsersQueries)}
              isLoading={isUsersLoading}
              searchIt={searchHandler(setUsersQueries)}
            />
          )}
        </Tab>
        <Tab eventKey="contacts" title="Contacts">
          {!isContactsFetched ? (
            <Loader/>
          ) : (
            <DataTable
              dataObj={contactsData}
              paginationHandler={paginationHandler(setContactsQueries)}
              isLoading={isContactsLoading}
              searchIt={searchHandler(setContactsQueries)}
            />
          )}
        </Tab>
      </Tabs>
      <WarningModal isLoading={isCompaniesLoading} show={isModalVisible} {...modalProps} />
    </div>
  );
};

export default Companies;